export default {
  sendMessage: async (values: TFormValues) => {
    const response = await fetch(`https://behind-closed-doors.lifestyleltd.ru/api/v1/mail/`, {
      method: 'post',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('Network error')
    }
  },
  getTheories: async ({game}: {game: 'first_game' | 'second_game'}) => {
    const response = await fetch(
      `https://behind-closed-doors.lifestyleltd.ru/api/v1/mail/?game=${game}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    if (!response.ok) {
      throw new Error('Network error')
    }
    return response.json()
  },
}
