import React, {FormEvent, useMemo, useRef, useState} from 'react'
import {Button} from './button'
import * as styles from '../assets/form.module.scss'
import classNames from 'classnames'
import {StaticImage} from 'gatsby-plugin-image'
import {validationSchema} from '../constants'

export const Form: React.FC<FormProps> = ({onSubmit, isLoading, isSuccess}) => {
  const formRef = useRef<HTMLFormElement | null>(null)
  const [form, setForm] = useState<TFormValues>({
    name: '',
    email: '',
    message: '',
  })
  const isValid = useMemo(() => validationSchema.isValid(form), [form])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    console.log()
    isValid.then((isValid) => {
      if (!isValid) return
      onSubmit(form).then(() => {
        const [input1, input2, textarea] = formRef.current?.children
        input1.value = ''
        input2.value = ''
        textarea.value = ''
      })
    })
  }

  const handleChange = ({target}: {target: {name: string; value: string}}) =>
    setForm({...form, [target.name]: target.value})

  return (
    <form onSubmit={handleSubmit} className={styles.container} ref={formRef}>
      <input
        placeholder="Никнейм"
        type="text"
        name="name"
        required
        onChange={handleChange}
      />
      <input
        placeholder="E-mail"
        type="email"
        required
        name="email"
        onChange={handleChange}
      />
      <textarea
        placeholder="Ваша теория"
        name="message"
        required
        onChange={handleChange}
        maxLength={500}
      />
      <Button
        onSubmit={handleSubmit}
        style={{margin: '0 auto'}}
        disabled={isLoading || !isValid}
        className={classNames({
          [styles.button__disabled]: isLoading || !isValid,
        })}
      >
        Отправить
      </Button>

      <StaticImage
        className={styles.substate_image}
        src="../images/footer.png"
        alt="footer-bg"
      />
    </form>
  )
}

type FormProps = {
  onSubmit: (values: TFormValues) => Promise<unknown>
  isLoading: boolean
  isSuccess: boolean
}
