import classNames from 'classnames'
import * as React from 'react'
import * as styles from '../assets/menu.module.scss'

export const Menu: React.FC<MenuProps> = ({menu, activeSection, isMobile}) => {
  const menuRef = React.useRef<HTMLDivElement | null>(null)
  const [isSticky, setSticky] = React.useState(false)

  const handleScroll = () => {
    if (window.scrollY >= (isMobile ? 230 : 330)) {
      setSticky(true)
    } else {
      setSticky(false)
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.addEventListener('scroll', handleScroll)
  }, [])

  return (
    <div
      className={classNames({
        [styles.menu__container]: true,
        [styles.menu__container__sticky]: isSticky,
      })}
    >
      <nav ref={menuRef}>
        <ul className={styles.menu}>
          {menu.map((item) => (
            <li
              key={item.path}
              className={classNames({
                [styles.menu_item]: true,
                [styles.menu_item__active]: activeSection === item.path,
              })}
            >
              <a href={`#${item.path}`}>{item.title}</a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

type MenuProps = {
  menu: TMenu[]
  activeSection: TSection
  isMobile: boolean
}
