import React from 'react'
import {Helmet} from 'react-helmet'

import '../assets/base/breakpoints.scss'
import '../assets/base/reset.scss'
import '../assets/base/index.scss'

export const Layout: React.FC = ({children}) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" lang="ru" />
        <title>За Закрытыми Дверями</title>
      </Helmet>
      {children}
    </>
  )
}
