import * as yup from 'yup'

export const menuDesktop: TMenu[] = [
  {title: 'Концепция игры', path: 'guilty', position: 2494},
  {title: 'Отзывы об игре', path: 'comments', position: 2924},
  {title: 'Игры серии', path: 'games', position: 4702},
  {title: 'Конкурс', path: 'competition', position: 5456},
  {title: 'Теории', path: 'theories', position: 5945},
  {title: 'Купить', path: 'buy', position: 7000},
]

export const menuMobile: TMenu[] = [
  {title: 'Концепция игры', path: 'guilty', position: 2357},
  {title: 'Отзывы об игре', path: 'comments', position: 2651},
  {title: 'Игры серии', path: 'games', position: 4277},
  {title: 'Конкурс', path: 'competition', position: 4950},
  {title: 'Теории', path: 'theories', position: 5377},
  {title: 'Купить', path: 'buy', position: 7000},
]

export const guiltyText = `
    «Торжественно клянусь, что буду осуществлять правосудие невзирая на лица и предоставлять равное право бедному и богатому; что буду искренне и беспристрастно исполнять все возложенные на меня обязанности наилучшим образом, насколько позволяют мои способности и знания, в согласии с Конституцией и законами. <br/><br/>
    «За Закрытыми Дверьми» (Intime Conviction) игра с новой оригинальной концепцией, в которой важно будет убедить ваших коллег в решении, которое вы считаете единственно верным. Сможете ли вы это сделать? <br/><br/>
    Перед нами не обычный квест с загадками, а новый уникальный формат игры в «убеждение» (в нашей стране принято такое понятие как «внутреннее убеждение судьи») в которой вам предстоит стать вершителем судеб, вынести приговор, в роли группы присяжных заседателей. И как зачастую, в нашей жизни, мы не узнаем всей правды. Сумеете ли вы убедить других в правильности вашего выбора? Или они проголосуют по-другому? <br/><br/>
    В кооперативной игре на дедукцию и умение убеждать - «За Закрытыми Дверьми», игроки выступают в роли группы присяжных заседателей, воссоздав атмосферу закрытого заседания в течение одного вечера или растянув дело на неделю, вскрывая новые факты дела каждый день. Сначала им предстоит изучить факты и доказательства с места преступления, проанализировать факты против обвиняемого, выслушать его показания. Вскрывая карты присяжных, которые будет делиться своим мнением, подвергать вас тем или иным сомнениям, находить новые вопросы, догадки и т.д. (Здесь тоже используется довольно оригинальная механика, на карточки присяжных игроки будут прикладывать красный фильтр, чтобы найти новую информацию по делу) Когда жюри будет готово к голосованию, проводится закрытое финальное голосование, где игроки должны будут большинством голосов решить, виновен ли обвиняемый в уголовном деле или нет. <br/><br/>
    В финальном голосовании только перед лицом своей твердой убежденности они должны будут найти путь к победе. После голосования, вскрывается последняя подсказка, в которой появляется совершенно новая информация. Это тот элемент, который позволит вам определить, соответствует ли судебная правда, которую ваши присяжные определили своим вердиктом, действительности или нет. Последняя подсказка намеренно оставляет пространство для интерпретации, чтобы обеспечить более близкий к реальности опыт глубокого убеждения. Действительно, обычно никто не приходит к вам и не говорит, были ли вы правы… <br/><br/>
    «За Закрытыми Дверьми» предлагает вам уникальный и увлекательный игровой опыт, который позволит вам смоделировать роль присяжных заседателей в уголовном суде, основанном на реальных событиях. <br/><br/> 
    Необычен и формат игры: она будет продаваться в крафтовом необычном кастомном конверте. Играть в неё можно от 2 до 12 игроков. И конечно, мы рекомендуем перед игрой, посмотреть легендарный фильм «12 разгневанных мужчин», в котором один из засомневавшихся присяжных перевернул полностью весь судебный процесс.
`

export const comments = [
  'https://www.youtube.com/embed/Q5lAa-xTgsc',
  'https://www.youtube.com/embed/LzzgB_MqUzY?list=PLUh4Zt6kvBZF3xOggXyP-aIBmo_Ut7pqS',
  'https://www.youtube.com/embed/bXYzxVcJoKw',
  'https://www.youtube.com/embed/loxLQ1YHTa0',
  'https://www.youtube.com/embed/3zdsgjaBX2A',
  'https://www.youtube.com/embed/iPgqB9FQh1',
  'https://www.youtube.com/embed/ACZoO_pgafU',
  'https://www.youtube.com/embed/uEc7_w6hoz8',
  'https://www.youtube.com/embed/CVm6RR7n8KE',
  'https://www.youtube.com/embed/Hkw3lJchBkY',
  'https://www.youtube.com/embed/3yHhwsLKLx4',
]

export const validationSchema = yup.object().shape({
  name: yup.string().required('Введите никнейм'),
  email: yup
    .string()
    .required('Введите почту')
    .email('Введите правильную почту'),
  message: yup.string().required('Опишите вашу теорию'),
})
