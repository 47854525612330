import React, {FC} from 'react'
import * as styles from '../assets/modal.module.scss'

const Modal: FC<ModalProps> = ({children, toggleModal}) => {
  return (
    <div className={styles.modal__backdrop} onClick={() => toggleModal(false)}>
      <div
        className={styles.modal__container}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  )
}

export default Modal

type ModalProps = {
  toggleModal(value: boolean): void
}
