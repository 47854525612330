import * as React from 'react'
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import * as styles from '../assets/slider.module.scss'
import classNames from 'classnames'
import {Navigation, Pagination, SwiperOptions} from 'swiper'

export const Slider: React.FC<SliderProps> = ({
  className,
  data,
  renderItem,
  offset = 2,
  breakpoints,
  id,
  pagination,
  arrowLeft,
  arrowRight,
}) => {
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  return (
    <div className={classNames([styles.swiper, className])}>
      <Swiper
        modules={[Navigation, Pagination]}
        className={classNames([styles.container, className])}
        spaceBetween={24}
        slidesPerView={offset}
        // breakpoints={breakpoints}
        pagination={
          pagination
            ? {
                clickable: true,
                el: `.slider-pagination-${id}`,
              }
            : false
        }
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        grabCursor
      >
        {data.map((item, key) => (
          <SwiperSlide key={key} style={{height: '100%'}}>
            {renderItem(item)}
          </SwiperSlide>
        ))}
      </Swiper>
      {arrowLeft && (
        <div
          ref={navigationPrevRef}
          className={classNames([
            styles.swiper__navigation,
            styles.swiper__navigation_back,
          ])}
        >
          {arrowLeft}
        </div>
      )}
      {arrowRight && (
        <div
          ref={navigationNextRef}
          className={classNames([
            styles.swiper__navigation,
            styles.swiper__navigation_next,
          ])}
        >
          {arrowRight}
        </div>
      )}
      {pagination && (
        <div className={`slider-pagination slider-pagination-${id}`}></div>
      )}
    </div>
  )
}

type SliderProps = {
  data: any[]
  className?: string
  renderItem: any
  offset: number
  id: number | string
  pagination?: boolean
  breakpoints?: {
    [width: number]: SwiperOptions
    [ratio: string]: SwiperOptions
  }
  arrowLeft?: JSX.Element
  arrowRight?: JSX.Element
}
