import * as React from 'react'
import classNames from 'classnames'
import {StaticImage} from 'gatsby-plugin-image'

import * as styles from '../assets/index.module.scss'
import {
  Button,
  Container,
  Form,
  Layout,
  Menu,
  SaleCard,
  Slider,
} from '../components'
import {comments, guiltyText, menuDesktop, menuMobile} from '../constants'
import api from '../api'
import {useState} from 'react'
import Modal from '../components/modal'

let isMobileBreakpoint = 768

const IndexPage = () => {
  const [isLoading, setLoading] = React.useState(false)
  const [isSuccess, setSuccess] = React.useState(false)
  const [modal, setModal] = React.useState(false)
  const [firstGameTheories, setFirstGameTheories] = React.useState([])
  const [secondGameTheories, setSecondGameTheories] = React.useState([])
  const [activeSection, setActiveSection] = React.useState<TSection>('guilty')
  const [screenWidth, setScreenWidth] = useState(0)

  const menu = React.useMemo(() => {
    return screenWidth < isMobileBreakpoint ? menuMobile : menuDesktop
  }, [screenWidth])
  const handleSubmit = (values: TFormValues) =>
    new Promise(async (res) => {
      try {
        setLoading(true)
        await api.sendMessage(values)
        toggleModal(true)
        setSuccess(true)
        res(true)
      } finally {
        setLoading(false)
      }
    })

  const toggleModal = (value: boolean) => setModal(value)

  const handleScroll = () => {
    const currentSection = menu.find(
      ({position}) => window.scrollY <= position,
    ) as TMenu
    setActiveSection(currentSection?.path ?? 'guilty')
  }

  const handleResize = () => {
    setScreenWidth(window.innerWidth)
  }

  React.useEffect(() => {
    setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    api
      .getTheories({game: 'first_game'})
      .then((res) => setFirstGameTheories(res))
    api
      .getTheories({game: 'second_game'})
      .then((res) => setSecondGameTheories(res))
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  React.useEffect(() => {
    window.removeEventListener('scroll', handleScroll)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [menu[0].position])

  return (
    <Layout>
      <section className={styles.header}>
        <Container className={styles.header__container}>
          <a href="https://lifestyleltd.ru" target="_blank">
            <StaticImage
              className={styles.header__add_logo}
              src="../images/LS_logo.png"
              alt="logo1"
              objectFit="contain"
            />
          </a>
          <StaticImage
            className={styles.header__main_logo}
            src="../images/game-logo.png"
            alt="logo2"
            objectFit="contain"
          />
        </Container>
      </section>
      <div className={styles.sticky__container}>
        <Menu
          activeSection={activeSection}
          menu={menu}
          isMobile={screenWidth < isMobileBreakpoint}
        />
        <main className={classNames([styles.section, styles.main])}>
          <Container className={styles.main__container}>
            <div className={styles.heading_container}>
              <h1 className={styles.heading_container__title1}>
                Воссоздайте события, скрытые за закрытыми дверями!
              </h1>
              <h1 className={styles.heading_container__title2}>
                Выступите в роли присяжных и примите верное решение!
              </h1>
            </div>
            <StaticImage
              className={styles.main__image}
              src="../images/3.png"
              alt="logo3"
            />
          </Container>
        </main>
        <section className={styles.main_strip}>
          <Container className={styles.main_strip__container}></Container>
          <StaticImage
            className={styles.main_strip__image}
            src="../images/shape.png"
            alt="logo2"
          />
        </section>
        <section className={classNames([styles.section, styles.main_action])}>
          <Container className={styles.main_action__container}>
            <h3 className={styles.main_action__text}>
              Используйте бонусный информационный буклет <br />
              для более полной игры!
            </h3>
            <Button className={styles.main_action__button}>
              Мир суда ассисов
            </Button>
          </Container>
        </section>
        <section
          id="guilty"
          className={classNames([
            styles.section,
            styles.section__with_sheet,
            styles.guilty,
          ])}
        >
          <div className={classNames([styles.title, styles.guilty__title])}>
            Виновен!
          </div>
          <Container className={styles.guilty__container}>
            <p
              className={styles.guilty__body}
              dangerouslySetInnerHTML={{__html: guiltyText}}
            ></p>
            <Button className={styles.guilty__button}>Демоверсия</Button>
            <div className={styles.guilty__prompt}>
              Скачать демоверсию игры «За закрытыми дверями»
            </div>
          </Container>
        </section>
        <section
          id="comments"
          className={classNames([styles.section, styles.comments])}
        >
          <div className={classNames([styles.title, styles.title__white])}>
            Отзывы об игре
          </div>
          <Container className={styles.comments__container}>
            <Slider
              id="slider-one"
              offset={2}
              pagination
              className={styles.comments__slider}
              data={comments}
              arrowLeft={
                <StaticImage
                  src="../images/arrow2.png"
                  alt="arrow-left"
                  objectFit="contain"
                />
              }
              arrowRight={
                <StaticImage
                  src="../images/arrow1.png"
                  alt="arrow-right"
                  objectFit="contain"
                />
              }
              renderItem={(comment: string) => (
                <div
                  className={styles.comments__slider_item}
                  style={{
                    backgroundColor: '#808080',
                  }}
                >
                  <iframe
                    width="100%"
                    height="100%"
                    src={comment}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </div>
              )}
            />
          </Container>
          <StaticImage
            className={styles.comments__bg_image}
            src="../images/footer.png"
            alt="footer-bg"
          />
        </section>
        <section
          id="games"
          className={classNames([
            styles.section,
            styles.section__with_sheet,
            styles.games,
          ])}
        >
          <div className={styles.title}>Игры серии</div>
          <Container className={styles.games__container}>
            <Slider
              id="slider-games"
              offset={1}
              data={['https://www.youtube.com/embed/iPgqB9FQh1o']}
              renderItem={(path: string) => (
                <div className={styles.games__slider}>
                  <iframe
                    width="100%"
                    height="100%"
                    src={path}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </div>
              )}
            />
            <h3 className={styles.games__prompt}>
              Вы можете сами выбрать продолжительность игры!
            </h3>
            <div className={styles.duration}>
              <div className={styles.duration_card}>
                <span className={styles.duration_card__number}>#1</span>
                <span className={styles.duration_card__text}>
                  Сыграйте за вечер в компании друзей, воссоздав дома атмосферу
                  судебного заседания!
                </span>
              </div>
              <div className={styles.duration_card}>
                <span className={styles.duration_card__number}>#2</span>
                <span className={styles.duration_card__text}>
                  Играйте в течение нескольких дней в небольшой команде,
                  раскрывая элементы понемногу!
                </span>
              </div>
            </div>
          </Container>
        </section>
        <div className={styles.in_sale_strip}>
          <Container>
            <span className={styles.in_sale_strip__title}>
              В продаже два сценария для игры!
            </span>
            <span className={styles.in_sale_strip__subtitle}>
              Выберите понравившееся дело и вынесете приговор!
            </span>
          </Container>
        </div>
        <section
          className={classNames([
            styles.section,
            styles.section__with_sheet,
            styles.in_sale,
          ])}
        >
          <Container className={styles.in_sale__container}>
            <SaleCard
              image={
                <StaticImage
                  className={styles.card__image_main}
                  src="../images/BOX-Fleurs1.png"
                  alt="Fleur 1"
                  objectFit="contain"
                />
              }
              title="ДЕЛО О ПАРОМЩИКЕ  🌊"
              description="«Никто и представить себе не мог, что в общине когда-нибудь произойдет нечто подобное. Как до этого дошло?»  Присяжные заседатели, примете ли вы правильное решение?"
            />
            <SaleCard
              reverse
              image={
                <StaticImage
                  className={styles.card__image_main}
                  objectFit="contain"
                  src="../images/BOX-Fleurs2.png"
                  alt="Fleur 2"
                />
              }
              title="ДЕЛО О винной пробке  🍷"
              description="«Неудачная шутка или хорошо спланированная месть? Гала-вечер начинался в дружеской атмосфере» Присяжные заседатели, примете ли вы правильное решение?"
            >
              <div
                className={styles.card__image}
                style={{
                  marginTop: '2rem',
                  height: '150px',
                  width: '100%',
                }}
              >
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/3zdsgjaBX2A"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </SaleCard>
          </Container>
        </section>
        <section
          id="competition"
          className={classNames([styles.section, styles.competition])}
        >
          <div className={classNames([styles.title, styles.title__white])}>
            Конкурс
          </div>
          <Container className={classNames([styles.competition__container])}>
            <p className={styles.competition__description}>
              Данная игра не дает ответа на вопрос, что конкретно произошло и
              как было совершено преступление. <br />
              <br /> Точного ответа не знаем даже мы) <br /> <br /> Поэтому
              объявляем конкурс на самую интересную версию событий! <br />{' '}
              <br /> До 31 августа можете присылать свои теории в
              соответствующих ветках форума, а мы выберем самые интересные,
              которые получат призы от издательства!
            </p>
            <Form
              isLoading={isLoading}
              isSuccess={isSuccess}
              onSubmit={handleSubmit}
            />
          </Container>
        </section>
        <section
          id="theories"
          className={classNames([
            styles.section,
            styles.section__with_sheet,
            styles.theories,
          ])}
        >
          <div
            className={classNames([styles.title, styles.title__with_subtitle])}
          >
            Самые интересные теории
          </div>
          <div
            className={classNames([styles.subtitle, styles.theories__danger])}
          >
            (внимание! Спойлеры)
          </div>
          <Container className={classNames([styles.competition__container])}>
            <h1 className={styles.theory_card__title}>Дело о паромщике</h1>
            {firstGameTheories.length ? (
              <Slider
                pagination
                id="slider-two"
                offset={1}
                className={styles.competition__slider}
                data={firstGameTheories}
                arrowLeft={
                  <StaticImage
                    src="../images/arrow_left.png"
                    alt="arrow-left"
                    objectFit="contain"
                  />
                }
                arrowRight={
                  <StaticImage
                    src="../images/arrow_right.png"
                    alt="arrow-right"
                    objectFit="contain"
                  />
                }
                renderItem={(theory: TFormValues) => {
                  const [isOpen, setOpen] = useState(false)

                  return (
                    <div className={styles.theory_card}>
                      <p className={styles.theory_card__description}>
                        {!isOpen ? (
                          <Button
                            onClick={() => setOpen(true)}
                            className={styles.main_action__button}
                            style={{margin: 0}}
                          >
                            Открыть
                          </Button>
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{__html: theory.message}}
                          />
                        )}
                      </p>
                    </div>
                  )
                }}
              />
            ) : (
              <span style={{marginBottom: '3rem'}}>Нет данных</span>
            )}
            <h1 className={styles.theory_card__title}>Дело о винной пробке</h1>
            {secondGameTheories.length ? (
              <Slider
                pagination
                id="slider-three"
                offset={1}
                className={styles.competition__slider}
                data={secondGameTheories}
                arrowLeft={
                  <StaticImage
                    src="../images/arrow_left.png"
                    style={{background: 'transparent'}}
                    alt="arrow-left"
                    objectFit="contain"
                  />
                }
                arrowRight={
                  <StaticImage
                    src="../images/arrow_right.png"
                    style={{background: 'transparent'}}
                    alt="arrow-right"
                    objectFit="contain"
                  />
                }
                renderItem={(theory: TFormValues) => {
                  const [isOpen, setOpen] = useState(false)

                  return (
                    <div
                      className={styles.theory_card}
                      style={{marginBottom: 0}}
                    >
                      <p className={styles.theory_card__description}>
                        {!isOpen ? (
                          <Button
                            onClick={() => setOpen(true)}
                            className={styles.main_action__button}
                            style={{margin: 0}}
                          >
                            Открыть
                          </Button>
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{__html: theory.message}}
                          />
                        )}
                      </p>
                    </div>
                  )
                }}
              />
            ) : (
              <span>Нет Данных</span>
            )}
          </Container>
        </section>
        <footer
          id="buy"
          className={classNames([styles.section, styles.markets])}
        >
          <div className={classNames([styles.title, styles.title__white])}>
            Купить игру
          </div>
          <Container className={styles.markets__container}>
            <div className={styles.markets__grid}>
              <a
                href="#"
                target="_blank"
                className={styles.markets__image_link}
              >
                <div className={styles.markets__image_container}>
                  <StaticImage
                    className={styles.markets__image}
                    src="../images/markets/igroved.png"
                    alt="market"
                    objectFit="contain"
                  />
                </div>
              </a>
              <a
                href="#"
                target="_blank"
                className={styles.markets__image_link}
              >
                <div className={styles.markets__image_container}>
                  <StaticImage
                    className={styles.markets__image}
                    src="../images/markets/dm.png"
                    alt="market"
                    objectFit="contain"
                  />
                </div>
              </a>
              <a
                href="#"
                target="_blank"
                className={styles.markets__image_link}
              >
                <div className={styles.markets__image_container}>
                  <StaticImage
                    className={styles.markets__image}
                    src="../images/markets/ozon.png"
                    alt="market"
                    objectFit="contain"
                  />
                </div>
              </a>
              <a
                href="#"
                target="_blank"
                className={styles.markets__image_link}
              >
                <div className={styles.markets__image_container}>
                  <StaticImage
                    className={styles.markets__image}
                    src="../images/markets/wb.png"
                    alt="market"
                    objectFit="contain"
                  />
                </div>
              </a>
              <a
                href="#"
                target="_blank"
                className={styles.markets__image_link}
              >
                <div className={styles.markets__image_container}>
                  <StaticImage
                    className={styles.markets__image}
                    src="../images/markets/hg.png"
                    alt="market"
                    objectFit="contain"
                  />
                </div>
              </a>
              <a
                href="#"
                target="_blank"
                className={styles.markets__image_link}
              >
                <div className={styles.markets__image_container}>
                  <StaticImage
                    className={styles.markets__image}
                    src="../images/markets/giga.png"
                    alt="market"
                    objectFit="contain"
                  />
                </div>
              </a>
              <a
                href="#"
                target="_blank"
                className={styles.markets__image_link}
              >
                <div className={styles.markets__image_container}>
                  <StaticImage
                    className={styles.markets__image}
                    src="../images/markets/leonardo.png"
                    alt="market"
                    objectFit="contain"
                  />
                </div>
              </a>
            </div>

            <div className={styles.footer__info}>
              <div className={styles.footer__logos}>
                <a target="_blank" href="https://vk.com/lifestyle_boardgames">
                  <svg
                    className={styles.footer__logo}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="#f6a500"
                  >
                    <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.581 15.997c.304 0 .429-.204.425-.458-.016-.958.356-1.474 1.03-.802.744.744.896 1.26 1.801 1.26h1.601c.403 0 .562-.13.562-.334 0-.432-.711-1.194-1.312-1.752-.844-.783-.882-.802-.156-1.744.9-1.169 2.079-2.667 1.037-2.667h-1.991c-.387 0-.414.217-.551.542-.498 1.173-1.443 2.693-1.803 2.461-.377-.243-.204-1.203-.175-2.63.008-.377.006-.636-.571-.77-.314-.073-.621-.103-.903-.103-1.137 0-1.922.477-1.477.56.785.146.711 1.846.527 2.58-.319 1.278-1.518-1.012-2.018-2.152-.12-.275-.155-.488-.586-.488h-1.627c-.247 0-.394.08-.394.258 0 .301 1.479 3.36 2.892 4.885 1.379 1.487 2.742 1.354 3.689 1.354z" />
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/user/LifeStyleLtdRu"
                >
                  <svg
                    className={styles.footer__logo}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="#f6a500"
                  >
                    <path d="M16.23 7.102c-2.002-.136-6.462-.135-8.461 0-2.165.148-2.419 1.456-2.436 4.898.017 3.436.27 4.75 2.437 4.898 1.999.135 6.459.136 8.461 0 2.165-.148 2.42-1.457 2.437-4.898-.018-3.436-.271-4.75-2.438-4.898zm-6.23 7.12v-4.444l4.778 2.218-4.778 2.226zm2-12.222c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z" />
                  </svg>
                </a>
              </div>
              <span className={styles.footer__text}>
                Copyright© Стиль жизни
              </span>
              <span className={styles.footer__text}>2022</span>
            </div>

            <StaticImage
              className={styles.substate_image}
              src="../images/footer.png"
              alt="footer-bg"
            />
          </Container>
        </footer>
      </div>
      {modal && (
        <Modal toggleModal={toggleModal}>
          <h3 className={styles.title}>Ваша теория была отправлена</h3>
        </Modal>
      )}
    </Layout>
  )
}

export default IndexPage
