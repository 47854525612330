import * as React from 'react'
import classNames from 'classnames'
import * as styles from '../assets/button.module.scss'

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <button className={classNames([styles.button, className])} {...props}>
      {children}
    </button>
  )
}

type ButtonProps = {
  className: string | string[]
} & React.ButtonHTMLAttributes<HTMLButtonElement>
