import * as React from 'react'
import classNames from 'classnames'
import * as styles from '../assets/index.module.scss'

export const Container: React.FC<ContainerProps> = ({children, className}) => {
  return (
    <div className={classNames([styles.container, className])}>{children}</div>
  )
}

type ContainerProps = {
  className?: string
}
