import * as React from 'react'
import classNames from 'classnames'
import * as styles from '../assets/sale.module.scss'

export const SaleCard: React.FC<ButtonProps> = ({
  image,
  description,
  title,
  children,
  reverse,
}) => {
  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles.container__reverse]: reverse,
      })}
    >
      {image}
      <div
        className={classNames({
          [styles.info]: true,
          [styles.info_reverse]: reverse,
        })}
      >
        <div className={styles.info__title}>{title}</div>
        <div className={styles.info__description}>{description}</div>
        {children}
      </div>
    </div>
  )
}

type ButtonProps = {
  image: JSX.Element
  title: string
  description: string
  reverse?: boolean
}
