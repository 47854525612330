// extracted by mini-css-extract-plugin
export var card__image = "index-module--card__image--ay3NN";
export var card__image_main = "index-module--card__image_main--n78R0";
export var comments = "index-module--comments--e2DJB";
export var comments__bg_image = "index-module--comments__bg_image--fzjur";
export var comments__container = "index-module--comments__container--uAHZb";
export var comments__slider = "index-module--comments__slider--wPTFf";
export var comments__slider_item = "index-module--comments__slider_item--tChpz";
export var competition__container = "index-module--competition__container--cKx9+";
export var competition__description = "index-module--competition__description--h1rHJ";
export var competition__slider = "index-module--competition__slider--qQ7f7";
export var container = "index-module--container--Ek1Bu";
export var duration = "index-module--duration--kwd6q";
export var duration_card = "index-module--duration_card--ok5tT";
export var duration_card__number = "index-module--duration_card__number--u4Z4k";
export var duration_card__text = "index-module--duration_card__text--gOtFd";
export var footer__info = "index-module--footer__info--3sFpN";
export var footer__logo = "index-module--footer__logo--ISeJi";
export var footer__logos = "index-module--footer__logos--bSGA1";
export var footer__text = "index-module--footer__text--6lKsr";
export var games__container = "index-module--games__container--gVOEs";
export var games__prompt = "index-module--games__prompt--prMZB";
export var games__slider = "index-module--games__slider--WXLqc";
export var guilty = "index-module--guilty--dXeOH";
export var guilty__body = "index-module--guilty__body--XoZN4";
export var guilty__button = "index-module--guilty__button--x4SjN";
export var guilty__container = "index-module--guilty__container--xFa6p";
export var guilty__prompt = "index-module--guilty__prompt--6jFc6";
export var guilty__title = "index-module--guilty__title--IZKPj";
export var header = "index-module--header--yiVpb";
export var header__add_logo = "index-module--header__add_logo--7LQX3";
export var header__container = "index-module--header__container--nUgMe";
export var header__main_logo = "index-module--header__main_logo--qnvST";
export var heading_container = "index-module--heading_container--KjkCR";
export var heading_container__title1 = "index-module--heading_container__title1--uKdF5";
export var in_sale = "index-module--in_sale--Te-s8";
export var in_sale__container = "index-module--in_sale__container--AUpym";
export var in_sale_strip = "index-module--in_sale_strip--O8sOH";
export var in_sale_strip__subtitle = "index-module--in_sale_strip__subtitle--oV9bQ";
export var main = "index-module--main--iM9Rn";
export var main__container = "index-module--main__container--bUdyg";
export var main__image = "index-module--main__image--qcimw";
export var main_action = "index-module--main_action--9uWt1";
export var main_action__button = "index-module--main_action__button--2-+VD";
export var main_action__container = "index-module--main_action__container--BKcaP";
export var main_action__text = "index-module--main_action__text--gM6Ie";
export var main_strip = "index-module--main_strip--75DAB";
export var main_strip__container = "index-module--main_strip__container--wHSEj";
export var main_strip__image = "index-module--main_strip__image--Jn7BC";
export var markets__container = "index-module--markets__container--kBHlX";
export var markets__grid = "index-module--markets__grid--aPzIp";
export var markets__image = "index-module--markets__image--tYU0Q";
export var markets__image_container = "index-module--markets__image_container--Xb6Lk";
export var section = "index-module--section--lFIo-";
export var section__with_sheet = "index-module--section__with_sheet--a-F8D";
export var sliderPagination = "index-module--slider-pagination--3dxvt";
export var sliderPaginationSliderThree = "index-module--slider-pagination-slider-three--pcxZ0";
export var sliderPaginationSliderTwo = "index-module--slider-pagination-slider-two--RQAQL";
export var sticky__container = "index-module--sticky__container--rT6iM";
export var substate_image = "index-module--substate_image--rBxaQ";
export var subtitle = "index-module--subtitle--ssNNC";
export var swiperButtonDisabled = "index-module--swiper-button-disabled--TJAB3";
export var swiperPaginationBullet = "index-module--swiper-pagination-bullet--vt4LP";
export var swiperPaginationBulletActive = "index-module--swiper-pagination-bullet-active--Q2cM9";
export var theories__danger = "index-module--theories__danger--JftkK";
export var theory_card = "index-module--theory_card--jy9CX";
export var theory_card__title = "index-module--theory_card__title--0jm7E";
export var title = "index-module--title--KwC9D";
export var title__white = "index-module--title__white--YVmX2";
export var title__with_subtitle = "index-module--title__with_subtitle--yiK0E";